<template>
  <div>
    <h1>这是首页</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
</style>